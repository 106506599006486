import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import useDeleteClaimFolder from '@/queries/useDeleteClaimFolder';
import { useClaimsFolderStorage } from '@/stores/claimsFolder';

import { ActionModals } from '@/types';

const useClaimFoldersModals = () => {
  const { deleteClaimFolder } = useDeleteClaimFolder();
  const { currentClaimsFolder } = useClaimsFolderStorage();
  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_CLAIM_FOLDER: {
      children: (
        <CommonModalLabel
          leadingText={t('claims-folders-page.action-modals.delete.leading')}
          redText={t('delete_unarchive_claim_confirm_red_text')}
          trailingText={t('claims-folders-page.action-modals.delete.trailing')}
        />
      ),
      onConfirm: () => {
        if (currentClaimsFolder) {
          deleteClaimFolder(currentClaimsFolder.id);
        }
      },
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useClaimFoldersModals;
