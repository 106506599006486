import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import useDeleteWorkflowFolder from '@/queries/useDeleteWorkflowFolder';
import { useWorkflowsFolderStorage } from '@/stores/workflowsFolder';

import { ActionModals } from '@/types';

const useWorkflowsFoldersModals = () => {
  const { currentWorkflowsFolder } = useWorkflowsFolderStorage();
  const { deleteWorkflowFolder } = useDeleteWorkflowFolder();
  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_WORKFLOW_FOLDER: {
      children: (
        <CommonModalLabel
          leadingText={t('workflow-folders-page.action-modals.delete.leading')}
          redText={t('delete_unarchive_workflow_confirm_red_text')}
          trailingText={t(
            'workflow-folders-page.action-modals.delete.trailing'
          )}
        />
      ),
      onConfirm: () => {
        if (currentWorkflowsFolder) {
          deleteWorkflowFolder(currentWorkflowsFolder.id);
        }
      },
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useWorkflowsFoldersModals;
