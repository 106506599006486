/* eslint-disable @typescript-eslint/no-explicit-any */

import { schema } from 'normalizr';

const commentSchema = new schema.Entity('comment_object');
const componentSchema = new schema.Entity('components', {
  comment_object: [commentSchema],
});

const questionSchema = new schema.Entity('questions', {
  components: [componentSchema],
});

export const formSchema = [
  new schema.Entity(
    'sections',
    {
      questions: [questionSchema],
      components: [componentSchema],
    },
    {
      processStrategy: (value: any) => {
        if (value.questions) {
          return {
            ...value,
          };
        }
        return {
          ...value,
        };
      },
    }
  ),
];
