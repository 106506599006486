import create, { State as ZustandState } from 'zustand';

import { EmailTaskInterface } from '@/types';

interface EmailTaskStorageStateInterface extends ZustandState {
  emailTasks: EmailTaskInterface[];
  currentEmailTask?: EmailTaskInterface;
  setEmailTasks: (emailTask: EmailTaskInterface[]) => void;
  setCurrentEmailTask: (emailTask?: EmailTaskInterface) => void;
  refetch?: () => void;
  setRefetch: (refetch?: () => void) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  checkedEmailTaskId?: number;
  setCheckedEmailTaskId: (checkedEmailIds?: number) => void;
}

export const useEmailTasksStorage = create<EmailTaskStorageStateInterface>(
  (set) => ({
    emailTasks: [],
    currentEmailTask: undefined,
    isSubmitting: false,
    checkedEmailTaskId: undefined,
    refetch: undefined,
    setRefetch: (refetch) => set({ refetch }),
    setCheckedEmailTaskId: (checkedEmailTaskId) => set({ checkedEmailTaskId }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setEmailTasks: (emailTasks) => set({ emailTasks }),
    setCurrentEmailTask: (currentEmailTask) => set({ currentEmailTask }),
  })
);
