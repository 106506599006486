import create, { State as ZustandState } from 'zustand';

import { InspectionInterface } from '@/types';

interface InspectionStorageStateInterface extends ZustandState {
  inspections: InspectionInterface[];
  currentInspection: InspectionInterface | null;
  isSubmitting: boolean;
  refetch?: () => void;
  setRefetch: (refetch: () => void) => void;
  setInspections: (inspections: InspectionInterface[]) => void;
  setCurrentInspection: (currentInspection: InspectionInterface | null) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useInspectionsStore = create<InspectionStorageStateInterface>(
  (set) => ({
    inspections: [],
    currentInspection: null,
    isSubmitting: false,
    refetch: undefined,
    setRefetch: (refetch) => set({ refetch }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setInspections: (inspections) => set({ inspections }),
    setCurrentInspection: (currentInspection) => set({ currentInspection }),
  })
);
