import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useCertificationStore } from '@/stores/certification';
import { useModalsStorage } from '@/stores/modals';

const mergeReports = async (reports: string[]) => {
  const response = await api.post(API_ENDPOINTS.MERGE_REPORTS(), {
    reports,
  });
  return response;
};

export default function useMergeReports() {
  const { t } = useTranslation('common');
  const { setSelectedCertificationIds } = useCertificationStore();
  const { setIsModalOpen } = useModalsStorage();

  const { mutate, isLoading, error, isSuccess, isError, reset } = useMutation(
    mergeReports,
    {
      onSuccess: () => {
        toast.success(t('reports-page.merge-reports-success'));
        setSelectedCertificationIds([]);
        setIsModalOpen(false);
      },
      onError: () => {
        toast.error(t('reports-page.merge-reports-error'));
      },
    }
  );

  return {
    isMergingReports: isLoading,
    error,
    mergeReports: mutate,
    isSuccess,
    isError,
    reset,
  };
}
