import create, { State as ZustandState } from 'zustand';

import { EmailDetails } from '@/types';

interface EmailStorageStateInterface extends ZustandState {
  emails: EmailDetails[];
  currentEmail?: EmailDetails;
  isSubmitting: boolean;
  checkedEmailIds: number[];
  refetch?: () => void;
  setRefetch: (refetch?: () => void) => void;
  setEmails: (tasks: EmailDetails[]) => void;
  setCurrentEmail: (currentTask?: EmailDetails) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setCheckedEmailIds: (checkedEmailIds: number[]) => void;
}

export const useEmailsStorage = create<EmailStorageStateInterface>((set) => ({
  emails: [],
  currentEmail: undefined,
  isSubmitting: false,
  checkedEmailIds: [],
  refetch: undefined,
  setRefetch: (refetch) => set({ refetch }),
  setCheckedEmailIds: (checkedEmailIds) => set({ checkedEmailIds }),
  setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
  setEmails: (emails) => set({ emails }),
  setCurrentEmail: (currentEmail) => set({ currentEmail }),
}));
