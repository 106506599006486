import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';

export default function useDeleteClaim() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (claimId: number) => api.delete(API_ENDPOINTS.WORKFLOW_DELETE(claimId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('claims');
        toast.success(t('claims-page.delete-success'));
      },
      onError: () => {
        toast.error(t('claims-page.delete-error'));
      },
    }
  );

  return {
    isDeletingClaim: isLoading,
    deleteClaim: mutate,
    deleteClaimStatus: status,
    deleteClaimSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
