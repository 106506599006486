import React, { useState } from 'react';

import useCookies from '@/hooks/useCookies';

import CookieSettings from './CookieSettings';
import Modal from '../ui/Modal/Modal';

import { CustomCookies } from '@/types';

interface CookieBannerProps {
  onAcceptedAllCookies: () => void;
}

const CookieBanner = ({ onAcceptedAllCookies }: CookieBannerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [customCookies, setCustomCookies] = useState<CustomCookies>({
    advertisement: 'true',
    others: 'true',
  });

  const {
    acceptAllCookies,
    setAdvertisementCookies,
    setOthersCookies,
    setAllAccepted,
  } = useCookies();

  const acceptAll = () => {
    acceptAllCookies();
    onAcceptedAllCookies();
  };

  const saveCookies = () => {
    setAdvertisementCookies(customCookies.advertisement);
    setOthersCookies(customCookies.others);

    if (
      customCookies.advertisement === 'true' &&
      customCookies.others === 'true'
    ) {
      onAcceptedAllCookies();
      setAllAccepted();
    }
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen((prevModalOpen) => !prevModalOpen);
    setIsBannerOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsBannerOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        className='inline-flex flex-col justify-between overflow-y-auto md:!h-[690px] md:!max-w-[690px]'
        onConfirm={saveCookies}
        onClose={closeModal}
        withConfirm
        confirmText='Save & Accept'
      >
        <CookieSettings
          setCustomCookies={setCustomCookies}
          customCookies={customCookies}
        />
      </Modal>
      {isBannerOpen && (
        <div className='sticky bottom-0 z-20 min-h-40 w-full bg-inkanFooter px-11 py-8'>
          <p className='mb-2 text-xl text-black'>Inkan&apos;s Cookies</p>
          <div className='flex flex-col items-center space-x-0 md:flex-row md:space-x-10'>
            <p className='grow text-sm leading-7 text-black'>
              We use cookies on our website to give you the most relevant
              experience by remembering your preferences and repeat visits. By
              clicking “Accept All”, you consent to the use of ALL the cookies.
              However, you may visit “Cookie Settings“ to provide a controlled
              consent.
            </p>
            <div className='justify- mt-4 flex w-full flex-row justify-between md:mt-0 md:w-auto md:space-x-3'>
              <button
                onClick={openModal}
                className='h-8 rounded-2xl bg-stone-300 px-10 text-xs font-bold text-black md:w-44'
              >
                Cookie Settings
              </button>
              <button
                className='h-8 rounded-2xl bg-red-300 px-10 text-xs font-bold text-black md:w-44'
                onClick={acceptAll}
              >
                Accept All
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
