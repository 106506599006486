export { default as fetchCertifications } from './fetchCertifications';

export const QUERIES = {
  certifications: 'certifications',
  user: 'user',
  certification_description: 'certification_description',
  billing_history: 'billing_history',
  geolocation: 'geolocation',
  tasks: 'tasks',
  claim_complete: 'claim_complete',
  workflow: 'workflow',
  workflows: 'workflows',
  workflow_details: 'workflow_details',
  workflow_folders: 'workflow_folders',
  claims: 'claims',
  claim: 'claim',
  claim_inspections: 'claim_inspections',
  inspections: 'inspections',
  task_details: 'task_details',
  claim_details: 'claim_details',
  claim_folders: 'claim_folders',
  task_folders: 'task_folders',
  task_users: 'task_users',
  tasks_for_user: 'tasks_for_user',
  users: 'users',
  user_details: 'user_details',
  subscription_information: 'subscription_information',
  form: 'form',
  form_details: 'form_details',
  form_lists: 'form_lists',
  forms: 'forms',
  task_form: 'task_form',
  reports_to_select: 'reports_to_select',
  emails: 'emails',
  email_tasks: 'email_tasks',
  clients: 'clients',
  folders: 'folders',
  template: 'template',
  inspections_for_select: 'inspections_for_select',
  users_for_folder: 'users_for_folder',
};
