import useTranslation from 'next-translate/useTranslation';

import useMergeReports from '@/queries/useMergeReports';
import { useCertificationStore } from '@/stores/certification';

import { ActionModals } from '@/types';

const useReportsModals = () => {
  const { selectedCertificationIds } = useCertificationStore();
  const { t } = useTranslation('common');
  const { mergeReports, isMergingReports } = useMergeReports();
  const modals: ActionModals = {
    MERGE_REPORTS: {
      children: (
        <div className='flex flex-col justify-center'>
          <div className='text-center'>
            <label className='inline-block'>{t('merge-reports-start')}</label>{' '}
            <label className='inline-block text-red-500'>
              {t('merge-reports-red')}
            </label>{' '}
            <label className='inline-block'>{t('merge-reports-end')}</label>{' '}
          </div>
        </div>
      ),
      onConfirm: () => {
        mergeReports(selectedCertificationIds);
      },
      isSubmitting: isMergingReports,
    },
  };
  return {
    modals,
  };
};

export default useReportsModals;
