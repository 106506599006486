import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import { useArchiveClaim } from '@/queries/useArchiveClaim';
import useDeleteClaim from '@/queries/useDeleteClaim';
import { useClaimsStorage } from '@/stores/claims';

import { ActionModals } from '@/types';

const useClaimFoldersModals = () => {
  const { currentClaim } = useClaimsStorage();
  const { deleteClaim } = useDeleteClaim();
  const { archiveClaim } = useArchiveClaim();

  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_CLAIM: {
      children: (
        <CommonModalLabel
          leadingText={t('delete_unarchive_claim_confirm_before')}
          redText={t('delete_unarchive_claim_confirm_red_text')}
          trailingText={t('delete_unarchive_claim_confirm_after')}
        />
      ),
      onConfirm: () => {
        if (currentClaim) {
          deleteClaim(currentClaim.id);
        }
      },
    },
    UNABLE_DELETE_CLAIM: {
      children: (
        <label className='flex flex-col items-center text-sm'>
          <span className='mr-2 text-red-500'>
            {t('unable_to_delete_claim_contains_certification_red')}
          </span>
          <span>{t('unable_to_delete_claim_contains_certification')}</span>
        </label>
      ),
      closeText: 'Ok',
    },
    ARCHIVE_CLAIM: {
      children: (
        <CommonModalLabel redText={t('archive_claim_confirm_red_text')} />
      ),
      onConfirm: () => {
        if (currentClaim) {
          archiveClaim(currentClaim.id);
        }
      },
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useClaimFoldersModals;
