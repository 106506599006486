import useTranslation from 'next-translate/useTranslation';

const CommonModalLabel = ({
  redText,
  leadingText,
  trailingText,
}: {
  redText: string;
  leadingText?: string;
  trailingText?: string;
}) => {
  const { t } = useTranslation('common');
  return (
    <div className='text-center'>
      <label className='inline-block text-sm'>
        {leadingText || t('delete_unarchive_task_confirm_before')}
      </label>
      <label className='mx-1 inline-block text-sm text-primary-500'>
        {redText}
      </label>
      <label className='inline-block text-sm'>
        {trailingText || t('delete_unarchive_task_confirm_after')}
      </label>
    </div>
  );
};

export default CommonModalLabel;
