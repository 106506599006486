import useModals from '@/hooks/modals/useModals';

import { useModalsStorage } from '@/stores/modals';

import Modal from '../ui/Modal/Modal';

const ModalContainer = () => {
  const { modals } = useModals();
  const { isModalOpen, setIsModalOpen, modalVariant } = useModalsStorage();

  return (
    modalVariant && (
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        {...modals[modalVariant]}
      />
    )
  );
};

export default ModalContainer;
