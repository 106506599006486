import create from 'zustand';

import { UserListDetails } from '@/types';

interface UsersStoreProps {
  selectedUser?: UserListDetails;
  userPanelOpen: boolean;
  setSelectedUser: (selectedUser?: UserListDetails) => void;
  setUserPanelOpen: (taskPanelOpen: boolean) => void;
  clearUser: () => void;
  refetch?: () => void;
  setRefetch: (refetch?: () => void) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useUsersStore = create<UsersStoreProps>((set) => ({
  selectedUser: undefined,
  userPanelOpen: false,
  isSubmitting: false,
  refetch: undefined,
  setRefetch: (refetch) => set({ refetch }),
  setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
  setSelectedUser: (selectedUser) => set({ selectedUser }),
  setUserPanelOpen: (userPanelOpen) => set({ userPanelOpen }),
  clearUser: () => set({ selectedUser: undefined }),
}));
