import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useClaimsFolderStorage } from '@/stores/claimsFolder';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';

export default function useDeleteClaimFolder() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { refetch } = useClaimsFolderStorage();
  const { setError } = useErrorStore();
  const { setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (claimId: number) => api.delete(API_ENDPOINTS.CLAIM_FOLDER_DELETE(claimId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('claims');
        toast.success(t('claims-folders-page.delete-success'));
      },
      onError: (error: Error) => {
        setError(error);
        toast.error(t('claims-folders-page.delete-error'));
      },
      onSettled: () => {
        refetch && refetch();
        setIsModalOpen(false);
      },
    }
  );

  return {
    isDeletingClaimFolder: isLoading,
    deleteClaimFolder: mutate,
    deleteClaimFolderStatus: status,
    deleteClaimFolderSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
