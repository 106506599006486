import { useQuery } from 'react-query';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { QUERIES } from '@/queries';

import { SubscriptionInfo } from '@/types';

const fetchSubscriptionInformation = async () => {
  const { data } = await api.get(API_ENDPOINTS.SUBSCRIPTION_INFORMATION());

  return data.data;
};

export default function useSubscriptionInformation() {
  const { data, error, refetch } = useQuery<SubscriptionInfo>(
    QUERIES.subscription_information,
    fetchSubscriptionInformation
  );

  return {
    subscription: data,
    isLoading: !error && !data,
    isError: error,
    refetch,
  };
}
