/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { useModalsStorage } from '@/stores/modals';

import { ErrorResponse } from '@/types';

const saveWebInspection = async (payload: any) => {
  try {
    return await api.post(
      `/inspections/save/${payload.inspectionId}`,
      payload.data
    );
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response?.status === 400) {
      throw {
        message:
          'You cannot save this inspection because it has already been submitted.',
      };
    }
    throw error;
  }
};

export default function useSaveWebInspection() {
  const { t } = useTranslation('common');
  const { setModalVariant, setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, error, isSuccess, reset } = useMutation(
    saveWebInspection,
    {
      onSuccess: async () => {
        toast.success(t('inspections-page.save-success'));
      },
      onError: (error: ErrorResponse) => {
        if (error.title && error.message) {
          return;
        }
        setModalVariant('SAVE_ERROR');
        setIsModalOpen(true);
      },
    }
  );

  return {
    isSavingWebInspection: isLoading,
    error,
    saveWebInspection: mutate,
    isSaveSuccess: isSuccess,
    resetSaveWebInspection: reset,
  };
}
