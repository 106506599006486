import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

export function useArchiveTask() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess, reset } = useMutation(
    (taskId: string) => api.post(`/tasks/archive/${taskId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast.success(t('archive_task_success'));
      },
      onError: () => {
        toast.error(t('archive_task_error'));
      },
    }
  );

  return {
    isArchiveTask: isLoading,
    archiveTask: mutate,
    archiveTaskStatus: status,
    archiveTaskSuccessfully: isSuccess,
    resetArchiveTask: reset,
  };
}

export function useUnArchiveTask() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess } = useMutation(
    (taskId: string) => api.post(`/tasks/unarchive/${taskId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast.success(t('unarchive_task_success'));
      },
      onError: () => {
        toast.error(t('unarchive_task_error'));
      },
    }
  );

  return {
    isArchiveTask: isLoading,
    unarchiveTask: mutate,
    unarchiveTaskStatus: status,
    unarchiveTaskSuccessfully: isSuccess,
  };
}
