/* eslint-disable @typescript-eslint/no-explicit-any */
import create, { State as ZustandState } from 'zustand';

import { ActionModalVariants } from '@/types';

interface ModalsStorageInterface extends ZustandState {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  modalVariant: ActionModalVariants;
  setModalVariant: (modalVariant: ActionModalVariants) => void;
}

export const useModalsStorage = create<ModalsStorageInterface>((set) => ({
  isModalOpen: false,
  setIsModalOpen: (isModalOpen) => set({ isModalOpen }),
  modalVariant: 'CERTIFICATION_ERROR',
  setModalVariant: (modalVariant) => set({ modalVariant }),
}));
