import create, { State as ZustandState } from 'zustand';

import { FolderInterface } from '@/types';

interface ClaimFolderStorageStateInterface extends ZustandState {
  claimsFolder: FolderInterface[];
  currentClaimsFolder?: FolderInterface;
  setClaimsFolder: (claims: FolderInterface[]) => void;
  setCurrentClaimsFolder: (currentClaim?: FolderInterface) => void;
  refetch?: () => void;
  setRefetch: (refetch: () => void) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useClaimsFolderStorage = create<ClaimFolderStorageStateInterface>(
  (set) => ({
    claimsFolder: [],
    currentClaimsFolder: undefined,
    isSubmitting: false,
    refetch: undefined,
    setRefetch: (refetch) => set({ refetch }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setClaimsFolder: (claimsFolder) => set({ claimsFolder }),
    setCurrentClaimsFolder: (currentClaimsFolder) =>
      set({ currentClaimsFolder }),
  })
);
