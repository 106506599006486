import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import useWebInspection from '@/hooks/useWebInspection';

import useSaveWebInspection from '@/queries/useSaveWebInspection';
import { useModalsStorage } from '@/stores/modals';
import { useWebInspectionStorage } from '@/stores/webInspection';
import { buildFormPayload } from '@/utils/webForm';

import { useNavigationObserver } from '../useWarningOnExit';

import { ActionModals } from '@/types';

const useWebInspectionModals = () => {
  const { t } = useTranslation('common');
  const {
    push,
    query: { inspectionId, folderId, workflowId },
  } = useRouter();
  const { resetStore, currentStep, stopNavigation, setStopNavigation } =
    useWebInspectionStorage();
  const { saveWebInspection } = useSaveWebInspection();
  const { buildFormDataForSave } = useWebInspection(currentStep);
  const { setIsModalOpen, setModalVariant } = useModalsStorage();

  const handleSaveInspection = () => {
    const formDataPayload = buildFormDataForSave();

    const formPayload = buildFormPayload(
      formDataPayload,
      parseInt(inspectionId as string)
    );

    saveWebInspection({
      inspectionId,
      data: formPayload,
    });
  };

  const handleNavigateAttempt = () => {
    setIsModalOpen(true);
    setModalVariant('DISCARD');
  };

  const confirmNavigation = useNavigationObserver({
    shouldStopNavigation: stopNavigation,
    onNavigate: handleNavigateAttempt,
  });

  const modals: ActionModals = {
    CERTIFICATION_ERROR: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>
            {t('web-inspection-page.modals.certification-error.red-text')}
          </label>
          <label>
            {t('web-inspection-page.modals.certification-error.ending-text')}
          </label>
        </div>
      ),
      closeText: 'OK',
    },
    NO_CONNECTION: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>
            {t('web-inspection-page.modals.no-connection.red-text')}
          </label>
          <label>
            {t('web-inspection-page.modals.no-connection.ending-text')}
          </label>
        </div>
      ),
      closeText: 'OK',
    },
    GO_BACK: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>{t('go-back-red')}</label>
          <label>{t('go-back-trailing')}</label>
        </div>
      ),
      closeText: 'Cancel',
      onConfirm: () => {
        resetStore();
        localStorage.removeItem('web-form');
        push(`/dashboard/${folderId}/${workflowId}/${inspectionId}`);
      },
    },
    SAVE_ERROR: {
      children: (
        <div className='flex flex-col text-center'>
          <label>{t('inspection-save-error')}</label>
        </div>
      ),
      closeText: 'Cancel',
      confirmText: 'Continue',
    },
    SAVE_CONFIRM: {
      children: (
        <div className='flex flex-col text-center'>
          <label>
            <span>{t('inspections-page.save-inspection-confirm.start')}</span>
            <span className='text-inkanTextRed'>
              {t('inspections-page.save-inspection-confirm.red-text')}
            </span>
            <span>{t('inspections-page.save-inspection-confirm.ending')}</span>
          </label>
        </div>
      ),
      onConfirm: () => {
        handleSaveInspection();
        setIsModalOpen(false);
      },
      closeText: 'Cancel',
      confirmText: 'Continue',
    },
    DISCARD: {
      children: (
        <div className='flex flex-col text-center'>
          <label className='text-inkanTextRed'>{t('go-back-red')}</label>
          <label>{t('go-back-trailing')}</label>
        </div>
      ),
      onConfirm: () => {
        resetStore();
        localStorage.removeItem('web-form');
        setStopNavigation(false);
        confirmNavigation();
        setIsModalOpen(false);
      },
      onClose: () => {
        setStopNavigation(true);
        setIsModalOpen(false);
      },
    },
  };
  return {
    modals,
  };
};

export default useWebInspectionModals;
