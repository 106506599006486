import useTranslation from 'next-translate/useTranslation';

import useDeleteUser from '@/queries/useDeleteUser';
import useSubscriptionInformation from '@/queries/useSubscriptionInformation';
import { useModalsStorage } from '@/stores/modals';
import { useUsersStore } from '@/stores/users';

import { ActionModals } from '@/types';

const useUsersModals = () => {
  const { t } = useTranslation('common');
  const { refetch: refetchSubscription } = useSubscriptionInformation();
  const { deleteUser } = useDeleteUser(refetchSubscription);
  const { selectedUser } = useUsersStore();
  const { setIsModalOpen } = useModalsStorage();
  const modals: ActionModals = {
    DELETE_USER: {
      children: (
        <div className='flex flex-col justify-center'>
          <div className='mb-5 px-6 text-center'>
            <label className='text-md inline-block font-semibold'>
              {t('delete-user-confirm-before')}
            </label>{' '}
            <label className='text-md inline-block font-semibold text-primary-500'>
              {t('delete-user-confirm-red-text')}
            </label>{' '}
            <label className='text-md inline-block font-semibold'>
              {t('delete-user-confirm-after')}
            </label>
          </div>
          <div className='mb-5 px-6 text-center'>
            <label className='inline-block text-sm'>
              {t('delete-user-subtitle')}
            </label>
          </div>
        </div>
      ),
      onConfirm: () => {
        if (selectedUser) deleteUser(selectedUser.id);
        setIsModalOpen(false);
      },
    },
  };
  return {
    modals,
  };
};

export default useUsersModals;
