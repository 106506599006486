import create from 'zustand';

interface Error {
  title?: string;
  message: string;
}

interface ErrorStoreProps {
  error?: Error;
  setError: (error: Error) => void;
}

export const useErrorStore = create<ErrorStoreProps>((set) => ({
  error: undefined,
  setError: (error) => set({ error }),
}));
