import useTranslation from 'next-translate/useTranslation';

import { useErrorStore } from '@/stores/error';

import { ActionModals } from '@/types';

const useErrorModals = () => {
  const { error } = useErrorStore();
  const { t } = useTranslation('common');
  const modals: ActionModals = {
    SERVER_ERROR: {
      children: (
        <>
          {error && (
            <div className='flex flex-col text-center'>
              {error.title && (
                <label className='inline-block text-sm text-inkanTextRed'>
                  {t(error?.title)}
                </label>
              )}
              <label className='inline-block text-sm'>
                {t(error?.message)}
              </label>
            </div>
          )}
        </>
      ),
    },
  };
  return {
    modals,
  };
};

export default useErrorModals;
