import create, { State as ZustandState } from 'zustand';

import { ClaimInterface } from '@/types';

interface ClaimStorageStateInterface extends ZustandState {
  claims: ClaimInterface[];
  currentClaim?: ClaimInterface;
  refetch?: () => void;
  setRefetch: (refetch: () => void) => void;
  setClaims: (claims: ClaimInterface[]) => void;
  setCurrentClaim: (currentClaim?: ClaimInterface) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useClaimsStorage = create<ClaimStorageStateInterface>((set) => ({
  claims: [],
  currentClaim: undefined,
  isSubmitting: false,
  refetch: undefined,
  setRefetch: (refetch) => set({ refetch }),
  setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
  setClaims: (claims) => set({ claims }),
  setCurrentClaim: (currentClaim) => set({ currentClaim }),
}));
