import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';

export default function useDeleteWorkflow() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setIsModalOpen, setModalVariant } = useModalsStorage();
  const { setError } = useErrorStore();

  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (workflowId: number) =>
      api.delete(API_ENDPOINTS.WORKFLOW_DELETE(workflowId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('workflows');
        toast.success(t('workflows-page.delete-success'));
        setIsModalOpen(false);
      },
      onError: () => {
        setIsModalOpen(true);
        setModalVariant('SERVER_ERROR');
        setError({
          message: t('workflows-page.delete-error'),
        });
      },
    }
  );

  return {
    isDeletingWorkflow: isLoading,
    deleteWorkflow: mutate,
    deleteWorkflowStatus: status,
    deleteWorkflowSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
