import create, { State as ZustandState } from 'zustand';

import { TaskDetails, TaskDetailsWithUsersData } from '@/types';

interface TaskStorageStateInterface extends ZustandState {
  tasks: TaskDetails[] | TaskDetailsWithUsersData[];
  currentTask?: TaskDetails | TaskDetailsWithUsersData;
  setTasks: (tasks: TaskDetails[] | TaskDetailsWithUsersData[]) => void;
  setCurrentTask: (
    currentTask?: TaskDetails | TaskDetailsWithUsersData
  ) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useTasksStorage = create<TaskStorageStateInterface>((set) => ({
  tasks: [],
  currentTask: undefined,
  isSubmitting: false,
  setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
  setTasks: (tasks) => set({ tasks }),
  setCurrentTask: (currentTask) => set({ currentTask }),
}));
