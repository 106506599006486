import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';

const archiveEmailTask = async (emailTaskId: number) => {
  const { data } = await api.post(
    API_ENDPOINTS.EMAIL_TASK_ARCHIVE(emailTaskId)
  );

  return data;
};

export function useArchiveEmailTask() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess, reset } = useMutation(
    archiveEmailTask,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workflows');
        toast.success(t('emails-page.archive-success'));
      },
      onError: () => {
        toast.error(t('emails-page.archive-error'));
      },
    }
  );

  return {
    isArchiveEmailTask: isLoading,
    archiveEmailTask: mutate,
    archiveEmailTaskStatus: status,
    archiveEmailTaskSuccessfully: isSuccess,
    resetArchive: reset,
  };
}
