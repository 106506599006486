import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { useUsersStore } from '@/stores/users';

export default function useDeleteUser(refetchSubscription: () => void) {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { refetch } = useUsersStore();

  const { mutate, isLoading, status, isSuccess } = useMutation(
    (userId: number) => api.delete(`/users/${userId}`),
    {
      onSuccess: () => {
        refetchSubscription && refetchSubscription();
        refetch && refetch();
        queryClient.invalidateQueries('users');
        toast.success(t('delete-user-success'));
      },
      onError: () => {
        toast.error(t('delete-user-error'));
      },
    }
  );

  return {
    isDeletingUser: isLoading,
    deleteUser: mutate,
    deleteUserStatus: status,
    deleteUserSuccessfully: isSuccess,
  };
}
