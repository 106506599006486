import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useEmailTasksStorage } from '@/stores/emailTasks';
import { useModalsStorage } from '@/stores/modals';

export default function useDeleteTaskFolder() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setIsModalOpen, setModalVariant } = useModalsStorage();
  const { refetch } = useEmailTasksStorage();
  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (taskEmailId: number) =>
      api.delete(API_ENDPOINTS.EMAIL_TASK_DELETE(taskEmailId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('emails');
        toast.success(t('emails-page.delete-success'));
        setIsModalOpen(false);
        refetch && refetch();
      },
      onError: () => {
        setModalVariant('DELETE_ERROR_HAS_INSPECTIONS');
        setIsModalOpen(true);
      },
    }
  );

  return {
    isDeletingEmailTask: isLoading,
    deleteEmailTask: mutate,
    deleteEmailTaskStatus: status,
    deleteEmailTaskSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
