/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useMutation } from 'react-query';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { ERROR_MESSAGES } from '@/constants/Errors';
import { useInspectionsStore } from '@/stores/inspections';
import { useModalsStorage } from '@/stores/modals';

const createInspection = async (payload: any) => {
  try {
    const { data } = await api.post(API_ENDPOINTS.CREATE_INSPECTION(), payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response?.status === 422) {
      throw {
        title: ERROR_MESSAGES.folder_name_already_exists.title,
        message: ERROR_MESSAGES.folder_name_already_exists.description,
      };
    }

    throw error;
  }
};

export function useCreateInspection() {
  const { setIsModalOpen } = useModalsStorage();
  const { refetch } = useInspectionsStore();
  const { mutate, isLoading, error, isSuccess, reset, data } = useMutation(
    createInspection,
    {
      onSuccess: async () => {
        setIsModalOpen(false);
        refetch && refetch();
      },
    }
  );

  return {
    createInspection: mutate,
    isCreatingInspeciton: isLoading,
    error,
    data,
    isCreateSuccess: isSuccess,
    resetCreateInspection: reset,
  };
}
