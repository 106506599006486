import { DeleteConfirmationModal } from '@/components/ui/Modal/DeleteConfirmationModal';

import { useUnArchiveTask } from '@/queries/useArchiveTaks';
import useDeleteTask from '@/queries/useDeleteTask';
import { useTasksStorage } from '@/stores/tasks';

import { ActionModals } from '@/types';

const useArchiveModals = () => {
  const { deleteTask } = useDeleteTask();
  const { currentTask } = useTasksStorage();
  const { unarchiveTask } = useUnArchiveTask();
  const modals: ActionModals = {
    DELETE_TASK: {
      children: <DeleteConfirmationModal actionSelect={'ModalDelete'} />,
      onConfirm: () => {
        deleteTask(currentTask?.id as string);
      },
    },
    UNARCHIVE_TASK: {
      children: <DeleteConfirmationModal actionSelect={'ModalUnarchive'} />,
      onConfirm: () => {
        unarchiveTask(currentTask?.id as string);
      },
    },
  };
  return {
    modals,
  };
};

export default useArchiveModals;
