import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

export default function useDeleteTask() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (taskId: string) => api.delete(`/tasks/${taskId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast.success(t('delete_task_success'));
      },
      onError: () => {
        toast.error(t('delete_task_error'));
      },
    }
  );

  return {
    isDeletingTask: isLoading,
    deleteTask: mutate,
    deleteTaskStatus: status,
    deleteTaskSuccessfully: isSuccess,
    resetDeleteTask: reset,
    isError: isError,
  };
}
