import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import useDeleteInspection from '@/queries/useDeleteInspection';
import { useEmailsStorage } from '@/stores/emails';

import { ActionModals } from '@/types';

const useEmailModals = () => {
  const { t } = useTranslation('common');
  const { currentEmail } = useEmailsStorage();
  const { deleteInspection } = useDeleteInspection();

  const modals: ActionModals = {
    DELETE_EMAIL: {
      children: (
        <CommonModalLabel
          redText={t('delete_confirm_red_text')}
          trailingText={t('download_confirm_text_email')}
        />
      ),
      onConfirm: () => {
        if (currentEmail) {
          deleteInspection(currentEmail.id);
        }
      },
    },
  };

  return {
    modals,
  };
};

export default useEmailModals;
