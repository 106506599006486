import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { ERROR_MESSAGES } from '@/constants/Errors';
import { useEmailsStorage } from '@/stores/emails';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';

const deleteInspection = async (id: number) => {
  try {
    const { data } = await api.delete(API_ENDPOINTS.INSPECTION_DELETE(id));
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status == 400) {
        throw {
          message: ERROR_MESSAGES.inspection_has_certifications,
        };
      }
      if (error.response?.status == 404) {
        throw {
          message: ERROR_MESSAGES.inspection_already_deleted,
        };
      }
      if (error.response?.status == 403) {
        throw {
          message: ERROR_MESSAGES.inspection_delete_no_permission,
        };
      }
    }

    throw error;
  }
};

export default function useDeleteInspection() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setError } = useErrorStore();
  const { refetch } = useEmailsStorage();
  const { setModalVariant, setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, status, isSuccess, reset, isError, error } =
    useMutation(deleteInspection, {
      onSuccess: async () => {
        queryClient.invalidateQueries('inspections');
        toast.success(t('delete_inspection_success'));
        refetch && refetch();
        setIsModalOpen(false);
      },
      onError: () => {
        setError({
          message: 'delete_inspection_error',
        });
        setModalVariant('SERVER_ERROR');
      },
    });

  return {
    isDeletingInspection: isLoading,
    deleteInspection: mutate,
    deleteInspectionStatus: status,
    deleteInspectionSuccessfully: isSuccess,
    resetDeleteInspection: reset,
    isError: isError,
    error,
  };
}
